<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="name"
                  return-object
                  v-model="bank"
                  :items="listBank"
                  :rules="[v => !!v || 'Bank is required']"
                  @change="onChangebank"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="number"
                  item-value="id"
                  v-model="accountNumber"
                  :items="accountNumbers"
                  :rules="[v => !!v || 'Account Number is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-btn color="success" @click="openDialog">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Items <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-bank-statement
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteBank="deleteItem"
    ></dialog-bank-statement>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogBankStatement from "@/components/DialogBankStatement";

export default {
  name: "create-bank-statement",
  components: {
    DialogBankStatement,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      date: moment().format("yyyy-MM-DD"),
      type: "",
      description: "",
      transactionCode: "",
      bankId: null,
      bankAccountNumberId: null,
      amount: 0,
      balance: 0,
      sequence: 1,
    },
    menuDate: false,
    items: [],
    accountNumbers: [],
    dialog: false,
    item: {},
    index: -1,
    bank: null,
    accountNumber: null,
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Transaction Code",
        value: "transactionCode",
        sortable: false,
        divider: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    alert: false,
  }),

  computed: {
    totalAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.amount;
      });
      return total;
    },

    ...mapState("bank", ["listBank"]),
    ...mapState("bankStatement", ["lastFormNumber"]),
  },

  watch: {},

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("bankStatement/create", this.items)
          .then(() => {
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    openDialog() {
      this.defaultForm.bankId = this.bank.id;
      this.defaultForm.bankAccountNumberId = this.accountNumber;
      let str = this.lastFormNumber.split(".");
      const lastNumber = this.lastFormNumber ? str[3] : "";
      const ctx = "" + (parseInt(lastNumber) + this.items.length);
      const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
      str[3] = end;
      this.defaultForm.formNumber = str.join(".");
      this.item = Object.assign({}, this.defaultForm);
      this.index = -1;
      this.dialog = true;
    },
    addItem(item) {
      if (this.index < 0) {
        this.items.push(item);
      } else {
        this.items[this.index] = item;
      }
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    onChangebank(val) {
      this.accountNumbers = val.accountNumbers;
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("bank/dropdown"),
        await this.$store.dispatch("bankStatement/getLastFormNumber"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
